import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../service/common.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-amission-course',
  templateUrl: './amission-course.component.html',
  styleUrls: ['./amission-course.component.css']
})
export class AmissionCourseComponent {
  
  admissionForm: FormGroup;
  submitted = false;
  randomOtp: any;
  otpInput: boolean = false
  otp_msg: any;
  otp_ValidMsg: boolean = false
  otp_inValidMsg: boolean = false
  countryList: any;
  selectedDevice:any;
  stateList: any=[];
  cityList: any=[];
  franchiseeList: any;
  studentID: any;
  program_id: any;
  gen_captcha: any;
  getCourseList:any=[];
  filterFranchisee: any;
  centerName: any;
  centercode:string="";
  fromcenterpage:string="";
  constructor(private fb: FormBuilder, private _servie: CommonService, private _activeRoute: ActivatedRoute, private router: Router) {
    this.admissionForm = fb.group({
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      // pinCode: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{6}$")]],
      // country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      otp: ['', Validators.required],
      center: ['', Validators.required],
      course: ['', Validators.required],
    
    })
  }

  ngOnInit(): void {
    // this.selectCountry_State_cityList();
    console.log('1')
    this.getCourseList = this._servie.course
    this.fromcenterpage=(localStorage.getItem('iscenter')!);
    localStorage.removeItem('iscenter');
    if(this.fromcenterpage!='true'){
      console.log('2')
      this.selectCountry_State_cityList();
    }else{
      this._servie.getcenterdt().subscribe(
        resp=>{          
         this.centercode=resp.Franchisee_Code
         this.centerName=resp.Franchisee_Name
        }
      );
    }
    // this.generateCAPTCHA()
  }
  get f() {
    return this.admissionForm.controls;
  }

  validationForm() {
    this.submitted = true;
    if (this.admissionForm.invalid) {     
     if(this.fromcenterpage=="true"){
        this.submitForm();
     }
     else{
      alert("All fields required");
     }
      // this.submit_captcha();
    } else {
      this.centercode=this.admissionForm.get('center')?.value;
      this.submitForm();
      // this.submit_captcha();
    }

  }

  submitForm() {
    if ((this.admissionForm.get('otp')?.value).length == 4) {
      if (this.randomOtp == this.admissionForm.get('otp')?.value) {
        let obj = {
          "City": this.admissionForm.get('city')?.value,
          "Country":1,
          "Email": this.admissionForm.get('email')?.value,
          "FirstName": this.admissionForm.get('fname')?.value,
          "HaveSpace": "",
          "LastName": this.admissionForm.get('lname')?.value,
          "Location": this.centercode,//id
          "Location_name": this.centerName,
          "Mobile": this.admissionForm.get('mobile')?.value,
          "PinCode":"",
          "class":this.admissionForm.get('course')?.value,
          "Product": "259262000033877033",
          "ProjectId": "3",
          "SoonStartsIn": "",
          "Source": "gclid",
          "gclid": "gclid",
          "State": this.admissionForm.get('state')?.value,
          "Type": "P",
          "WillingToInvest": "",
          "utm_compaign": "Website",
          "utm_medium": "Website",
          "utm_source": "Website",
          "utm_ad": "Website",
          "utm_Content": "Website",
          "utm_Term": "Website",
          "Stream":"",
          
        }
        this._servie.saveData(obj).subscribe(
          res => {
            this.otp_ValidMsg = false;
            this.otp_inValidMsg = false;
            this.router.navigate(['/thankyou'])
            this.admissionForm.reset();
            this.submitted = false;
          }
        )
        this.otp_ValidMsg = true;
        this.otp_inValidMsg = false;

      } else {
        alert('Please Enter Valid OTP')
        this.otp_inValidMsg = true;
        this.otp_ValidMsg = false;
      }
    }


  }


  selectCentet(getCenter:any){
   
if(!Array.isArray(this.filterFranchisee[0].Franchisee))
    {
    this.franchiseeList = [this.filterFranchisee[0].Franchisee];
    this.centerName=this.franchiseeList[0].Franchisee_Name
    }
    else
    {
      this.franchiseeList = this.filterFranchisee[0].Franchisee;
      this.centerName=this.franchiseeList[0].Franchisee_Name
    }
  }
  getMobileNO() {
   if ((this.admissionForm.get('mobile')?.value).length == 10) {
      this.sendMobNO();
    }
  }

  sendMobNO() {
    this.randomOtp = Math.floor(1000 + Math.random() * 9000);
    let mobNo = {
      "MobileNo": this.admissionForm.get('mobile')?.value,
      "smsText": `To validate your ZICA interest use One Time Password ${this.randomOtp}. Think Education. Think Zee Learn.`,
      "sResponse": ""
    }
    this._servie.getOtp(mobNo).subscribe(
      res => {
        this.otpInput = true;
      }
    )
  }
  verifyOTP() {
    if ((this.admissionForm.get('otp')?.value).length == 4) {
      if (this.randomOtp == this.admissionForm.get('otp')?.value) {
        this.otp_ValidMsg = true;
        this.otp_inValidMsg = false;
      } else {
        this.otp_inValidMsg = true;
        this.otp_ValidMsg = false;
      }
    }
  }

  selectCountry_State_cityList() {
    this._servie.getState_countryList().subscribe(
      res => {
        this.countryList = res.root.subroot
        this.stateList = this.countryList.State
       })
  }


  selectState(selectVal: any) {
    
    let state = selectVal.target.value
    let filterCity = this.stateList.filter((x: any) => {
      return x.State_Name == state
    })
    
    if(!Array.isArray(filterCity[0].City))
    {

      this.cityList=[filterCity[0].City];
    }
    else
    {
      this.cityList = filterCity[0].City;

    }
  }

  selectCity(selectVal: any) {
    let city = selectVal.target.value
    this.filterFranchisee = this.cityList.filter((x: any) => {
      return x.City_Name == city
    })
    if(!Array.isArray(this.filterFranchisee[0].Franchisee))
    {
    this.franchiseeList = [this.filterFranchisee[0].Franchisee];
    }
    else
    {
      this.franchiseeList = this.filterFranchisee[0].Franchisee;

    }
  }
}
