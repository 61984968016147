import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  project$: Observable<any> | undefined;
  placementLogoList: any;
  showAllLogo:boolean = false
  careersPlacementList: any;
  filterListPlacement: any;
  constructor(
    private route: ActivatedRoute,
    private seoService: HomeSeoService,
    private projectService: ProjectSeoService,
    private apiService: ApicallService,
    private ngxSpinner: NgxSpinnerService,
    private _service: CommonService,

  ) {

  }
  ngOnInit(): void {
    //  this.getseo();
      
    setTimeout(()=>{
      // this.getLogoList()
      this.getseo();
    },1500)
    
  }
  routing(){
  }
  getseo() {
    
    let tbody = {
      slug: 'home',
      Projectid: environment.projectid,
    };
    this.apiService.getGetseo(tbody).subscribe((data: any) => {

      this.projectService.sendMessageblog(data?.data?.blog);
      this.projectService.sendMessageseo(data?.data?.testimony);
      this.projectService.sendMessageFaqs(data?.data?.faq);
      this.projectService.setmeta(data?.data);
      this.getLogoList();
    });
  }

  getLogoList(){
    let obj = {
      "Type":"PLACEMENT COMPANY",
      "Project_Id":environment.projectid
    }
    this._service.getCMSContent(obj).subscribe(
      res=>{
  this.placementLogoList = JSON.parse(res.data[0].contentData)
  this.getPlacementList()
      }
    )
  }

  showAllLosgoList(){
    this.showAllLogo = true
  }

  getPlacementList(){
    let obj = {
      "Type":"CAREER PLACEMENT",
      "Project_Id":environment.projectid
    }
  this._service.getCMSContent(obj).subscribe(
    res=>{
      this.careersPlacementList = JSON.parse(res.data[0].contentData) 
   console.log('list',this.careersPlacementList);
      this.filterListPlacement =  this.careersPlacementList.filter((x:any)=>{
         return x.category == 71
      })
      
      console.log('list2',this.filterListPlacement);
    
    }
  )
  }

}
